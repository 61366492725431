<template>
  <div
    ref="tooltip"
    class="Tooltip relative group"
  >
    <div class="cursor-pointer" @click="showTooltip = !showTooltip">
      <slot />
    </div>
    <div
      class="tooltip type-xs"
      :class="{
        'group-hover:block': showOnHover,
        'hidden': !showTooltip && !permanent,
        'bottom': position === 'bottom',
      }"
    >
      <div class="arrow" :class="{ 'bottom': position === 'bottom' }" />
      <span v-html="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

type Props = {
  text: string;
  showOnHover?: boolean;
  position?: string;
  permanent?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showOnHover: false,
  position: 'top',
  permanent: false,
});
const showTooltip = ref(false);
const tooltip = ref<HTMLElement>();

if (!props.permanent) {
  onClickOutside(tooltip, () => {
    showTooltip.value = false;
  });
}
</script>

<style scoped>
.tooltip {
  @apply w-176 absolute bg-lighter border border-light p-12 z-modalsContent text-center;
  left: 50%;
  transform: translateX(-50%);

  &:not(.bottom) {
    bottom: calc(100% + 12px);

    &::before {
      bottom: -19px;
    }
  }

  &::before {
    @apply border-light absolute border-[10px] left-1/2;
    content: '';
    border-color: #FAF6F4 transparent transparent transparent;
    transform: translateX(-50%);
  }

  &.bottom {
    top: calc(100% + 12px);

    &::before {
      @apply rotate-180 left-[44.25%];
      top: -19px;
    }
  }
}

.arrow {
  @apply absolute w-0 h-0 left-1/2 z-below;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: translateX(-50%);
}

.arrow:not(.bottom) {
  @apply -bottom-[10px];
  border-top: 10px solid #DEDDD9;
}

.arrow.bottom {
  @apply -top-[10px];
  border-bottom: 10px solid #DEDDD9;
}
</style>
